.wrap {
  padding: 60px 0 0;
  overflow: hidden;
}

.wrap:first-child {
  padding-top: 28px;
}

.note {
  display: block;
  font-size: 16px;
  line-height: 20px;
}

.title {
  font-size: 34px;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 32px;
}

.vizHeader {
  margin-bottom: 16px;
}

.vizTitle {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}

.vizSub {
  margin: 0;
}

.vizWrap {
  display: grid;
  grid-gap: 30px;
  padding-bottom: 60px;
  border-bottom: 1px solid #8e97a4;
}

.note {
  grid-column-span: 2;
  max-width: 815px;
}

.last .vizWrap {
  border-bottom: 0;
}

@media (min-width: 768px) {
  .vizWrapTwo {
    grid-template-columns: 1fr 1fr;
  }

  .vizWrapTwo > div:nth-child(3) {
    grid-column: 1 / span 2;
  }
}
