:root {
  --bw-primary-color: #4088ee;

  /* TJ */
  --bw-primary-color: #169b00;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  color: #222222;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: tabular-nums;
  background: #f5f5f5;

  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }
}

html {
  scroll-behavior: smooth;
}

#root h1,
#root h2,
#root h3 {
  color: inherit;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
