.row:not(:last-child) {
  margin-bottom: 16px;
}

.bar {
  min-height: 50px;
  border: 1px solid #8e97a4;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  background: #fff;
  position: relative;
}

.bar:nth-child(2) {
  margin-top: -50px !important;
}

.bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  bottom: 0;
  background: #f5f5f5;
  transition: 1s ease-out;
  transform-origin: center right;
  transform: scaleX(0);
  transition: 1s var(--delay) ease-out;
  z-index: 1;
}

[data-inview="false"] .bar:after {
  transform: scaleX(1);
}

.section {
  border-left: 1px solid #8e97a4;
  border-right: 1px solid #8e97a4;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: var(--color);
}

.section:first-child {
  border-left: 0;
}

.section:not(:first-child):not(:last-child) {
  border: 0;
}

.section:last-child {
  border-right: 0;
}

.label {
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: calc(var(--offset) * 1%);
  transform: translate(-50%, -50%);
  font-size: 14px;
  transition: 0.5s calc(var(--delay) + 0.5s) ease-out;
}

@media (max-width: 630px) {
  .labelIsTight {
    display: none;
  }
}

.labelIsTiny {
  display: none;
}

[data-inview="false"] .label {
  opacity: 0;
}

.legend {
  /* max-width: 320px; */
  margin: 60px 0;
}

@media (min-width: 768px) {
  .legend {
    margin-left: 180px;
  }

  .row {
    display: grid;
    grid-template-columns: 180px 1fr;
    align-items: center;
  }

  .bar {
    min-height: 50px;
  }

  .bar:nth-child(2) {
    margin-top: -50px !important;
  }

  .label {
    font-size: 20px;
  }
}
