.wrap {
  background: #ffffff;
  border: 1px solid #dcdfe2;
  padding: 30px;
  margin-bottom: 30px;
  max-width: 815px;
}

.wrap p,
.button {
  margin: 0;
}

.wrap p:not(:last-child),
.button {
  margin-bottom: 30px;
}

.button {
  display: inline-block;
  border: 0;
  background: var(--bw-primary-color);
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  font-weight: bold;
  margin-right: 30px;
}

.answer {
  color: #8e97a4;
  display: inline-block;
  transition: 0.4s ease-out;
  opacity: 0;
  padding: 8px 0 0 0;
}

.answer--isVisible {
  opacity: 1;
}
