.wrap {
  background: #0e2576;
  padding: 60px 0;
  color: #fff;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-top: 60px;
  overflow: hidden;
}

.title {
  background: #0e2576;
  padding: 0 16px;
  text-align: center;
  display: inline-block;
  z-index: 1;
  position: relative;
  margin: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}

.inner {
  border: 1px solid;
  padding: 16px;
  margin-top: -20px;
  display: flex;
  grid-gap: 2rem;
  padding: 60px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item {
  flex: 1;
  /* max-width: 290px; */
  text-align: center;
  padding: 0 16px;
}

.inner:not(:last-child) {
  margin-bottom: 16px;
}

.wrap:not(:last-child) {
  margin-bottom: 60px;
}

.icon {
  margin-bottom: 16px;
}

.itemTitle,
.itemText {
  margin: 0;
  margin-bottom: 0;
}

.itemText:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: 700px) {
}

:global(.tj) .wrap,
:global(.tj) .title {
  background: #cccccf;
  color: #000;
  background: url(./mixed-bg.svg);
  background-position: center;
  background-size: cover;
}

:global(.tj) .isFirst .title,
:global(.tj) .title {
  background-size: 1000px;
}

:global(.tj) .wrap.isFirst,
:global(.tj) .isFirst .title {
  background: url(./female-bg.svg);
  background-position: center;
  background-size: cover;
}

:global(.tj) .isFirst .title {
  background-size: 1000px;
}
