.wrap {
  background: #0e2576;
  color: #fff;
  padding: 60px 0;
}

.title {
  font-weight: bold;
  margin-bottom: 32px;
  margin-top: 0;
}

.wrap a {
  color: inherit;
}

.source {
  margin: 0;
}

:global(.tj) .wrap {
  background: #cccccf;
  color: #000;
}
