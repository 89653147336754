.xs {
  font-size: 12px;
  line-height: 14px;
}

.xs {
  font-size: 14px;
  line-height: 16px;
}

.s {
  font-size: 16px;
  line-height: 20px;
}

.m {
  font-size: 20px;
  line-height: 32px;
}

.l {
  font-size: 24px;
  line-height: 36px;
}

.xl {
  font-size: 30px;
  line-height: 40px;
}

.xxl {
  font-size: 34px;
  line-height: 40px;
}

.mega {
  font-size: 36px;
  line-height: 48px;
}

@media (min-width: 768px) {
  .xxs {
    font-size: 14px;
    line-height: 16px;
  }

  .xs {
    font-size: 16px;
    line-height: 20px;
  }

  .s {
    font-size: 20px;
    line-height: 32px;
  }

  .m {
    font-size: 24px;
    line-height: 36px;
  }

  .l {
    font-size: 30px;
    line-height: 40px;
  }

  .xl {
    font-size: 34px;
    line-height: 40px;
  }

  .xxl {
    font-size: 48px;
    line-height: 64px;
  }

  .mega {
    font-size: 60px;
    line-height: 64px;
  }
}
