.wrap {
}

.title,
.text {
  margin: 0;
  max-width: 815px;
}

.title {
  margin-bottom: 32px;
  margin-top: 70px;
}

.text {
  margin-bottom: 32px;
}
