.wrap {
  /* background-color: #fff; */
  background: #cccccf;
}

.footer {
  padding: 18px 0;
  font-size: 14px;
  letter-spacing: -0.29px;
  line-height: 1.42;
  border-top: 1px solid;
  color: #000;
}

.inner {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 4px;
}

.copy,
.footer p {
  margin: 0;
}

.footer p {
  margin-bottom: 12px;
}

.nav {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
}

.link {
  text-decoration: none;
  color: var(--theme-color);
}
