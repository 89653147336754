.header {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  position: relative;
  z-index: 9;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 18px 0px;
}

.link svg {
  display: block;
}
