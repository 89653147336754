.wrap {
}

.bar {
  background: #fff;
  padding: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bar:not(:last-child) {
  margin-bottom: 16px;
}

.bar:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  bottom: 0;
  background: var(--fill);
  z-index: -1;
  transform: translateX(var(--offset));
  transition: 1s var(--delay) ease-out;
}

[data-inview="false"] .bar:before {
  transform: translateX(0);
}
