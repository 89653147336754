.wrap {
  border: 1px solid #dcdfe2;
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  display: grid;
  grid-gap: 30px;
  color: #000;
  margin: 60px 0;
}

.image {
  max-width: 100%;
}

.copy {
  display: grid;
  grid-gap: 16px;
}

.title {
  margin: 0;
}

.text {
  margin: 0;
}

@media (min-width: 768px) {
  .wrap {
    grid-template-columns: 308px 1fr;
  }
}
