.wrap {
  display: inline-flex;
  border: 0;
  background: var(--bw-primary-color);
  color: #fff !important;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  /* gap: 10px; */
}

.wrap svg {
  display: block;
  margin-left: 10px;
}
