.wrap {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 88px;
  overflow: hidden;
}

.title {
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  margin-bottom: 30px;
}

.hasTop .title {
  margin-bottom: 80px;
}

.bar {
  min-height: 50px;
  border: 1px solid #8e97a4;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.bar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  bottom: 0;
  background: #f5f5f5;
  transition: 1s ease-out;
  transform-origin: center right;
  transform: scaleX(0);
  will-change: transform;
}

[data-inview="false"] .bar:after {
  transform: scaleX(1);
}

.bar:nth-child(2) {
  margin-top: -50px !important;
}

.small {
  min-height: 25px;
}

.small:nth-child(2) {
  margin-top: -25px !important;
}

.section {
  border-left: 1px solid #8e97a4;
  border-right: 1px solid #8e97a4;
  background: var(--color);
  transition: 1s ease-out;
  position: relative;
}

.section:first-child {
  border-left: 0;
}

.section:not(:first-child):not(:last-child) {
  border: 0;
}

.section:last-child {
  border-right: 0;
}

.sectionLabel:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 2px;
  right: 2px;
  height: 8px;
  border: 1px solid #8e97a4;
  border-bottom: 0;
  margin: 0.5rem 0;
}

.sectionLabel {
  position: absolute;
  bottom: 100%;
  margin-bottom: 1.5rem;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 10px;
  line-height: 1.2;
  /* line-height: 20px; */
  overflow: visible;
}

.sectionLabelBelow {
  bottom: auto;
  top: 100%;
  margin-bottom: 0;
  margin-top: 1.5rem;
}

.sectionLabelBelow:after {
  border: 1px solid #8e97a4;
  border-top: 0;
  top: auto;
  bottom: 100%;
}

.sectionLabelText {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 239px;
}

.sectionLabelBelow .sectionLabelText {
  bottom: auto;
  top: 100%;
}

.sectionNum {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: block;
}

.section:first-child .sectionLabelBelow .sectionLabelText {
  left: 0;
  transform: none;
  text-align: left;
}

.section:last-child .sectionLabelBelow .sectionLabelText {
  text-align: right;
  left: auto;
  right: 0;
  transform: none;
}

@media (min-width: 419px) {
  .sectionLabel {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .wrap {
    padding-bottom: 100px;
  }

  .hasTop .title {
    margin-bottom: 100px;
  }

  .bar {
    min-height: 150px;
  }

  .small {
    min-height: 25px;
  }

  .bar:nth-child(2) {
    margin-top: -150px !important;
  }

  .small:nth-child(2) {
    margin-top: -25px !important;
  }
}
