.wrap {
  margin-bottom: 24px;
  overflow: hidden;
}

.header {
  color: #fff;
  padding: 48px 0;
  background: url(./ss-header.svg);
  background-position: top -150px left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0c2577;
}

.title {
  margin: 0;
  font-weight: bold;
  margin-bottom: 24px;
  max-width: 815px;
}

.text {
  max-width: 815px;
}

.navWrap {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(143, 148, 163, 0.6);
  display: none;
  margin-right: -16px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
}

.items {
  display: flex;
  align-items: flex-end;
}

.navItem {
  font: inherit;
  color: #292a36;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  padding-top: 20px;
  padding-bottom: 12px;
  transition: 0.2s ease-out;
}

.navItem {
  margin-right: 16px;
}

.navItem:hover,
.navItem:first-child {
  border-color: var(--bw-primary-color);
}

.navItem:hover {
  text-decoration: none;
  color: inherit;
}

.navItem:first-child {
  font-weight: bold;
}

@media (min-width: 960px) {
  .navWrap {
    display: block;
  }

  .header {
    padding: 60px 0;
  }
}

@media (min-width: 810px) {
  .navItem {
    margin-right: 30px;
  }

  .navWrap {
    margin-right: 0;
  }
}

:global(.tj) .header {
  text-align: center;
  background: url("./tj-header.svg");
  color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
:global(.tj) .title,
:global(.tj) .text {
  margin-left: auto;
  margin-right: auto;
}

:global(.tj) .title {
  margin-top: 90px;
}

:global(.tj) .text {
  max-width: 640px;
  font-weight: bold;
}
