.wrap {
}

.row {
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-gap: 2rem;
}

.row:not(:last-child) {
  margin-bottom: 16px;
}

.word {
  display: inline-block;
  padding: 4px;
  border: 1px solid var(--fill);
  position: relative;
  z-index: 1;
}

.word:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--fill);
  z-index: -1;
  opacity: 0.25;
}

.bar {
  padding: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bar:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  bottom: 0;
  background: var(--fill);
  z-index: -1;
  transform: translateX(var(--offset));
  transition: 1s var(--delay) ease-out;
}

[data-inview="false"] .bar:before {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .row {
    grid-template-columns: 180px 1fr;
  }
}
